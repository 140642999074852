.custom-select-container {
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
}

.custom-select-menu {
  box-shadow: none !important;
  position: unset !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.custom-select-footer {
  margin-top: 0;
  font-family: 'Roboto';
  font-size: 0.875rem;
}

.custom-select-footer a {
  color: #4a90e2 !important;
  cursor: pointer;
  z-index: 4;
}

.custom-select-footer > div > div {
  height: 40px;
  padding: 0 8px;
}

.custom-select-footer > div > div * {
  margin-left: 15px !important;
}
.custom-select-footer > div > div *:first-child {
  margin-left: 0 !important;
}
.custom-select-footer span {
  font-weight: 400;
}
